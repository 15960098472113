<template>
<article id="page-newcontent">
  <Collection  :name="`post/${id}`"  v-slot="{ model }">
  <div class="breadcrumb">
    <ol class="w-1200 r m-auto">
      <li><a href="/" class="home"><a-icon type="home" />首页</a></li>
      <li>
        <router-link :to="`/article/list/${model.target}`">{{model.target_name}}</router-link>
      </li>
      <li><a href="javascript:;">详情</a></li>
    </ol>
  </div>
  <section class="container">
    <div class="row">
      <div class="content" id="printart">
        <h1 id="main_title">{{model.post_title}}</h1>
        <div class="article-infos">
          <p> <span>发布时间：{{ moment(model.created).format('YYYY-MM-DD') }}</span> <span>来源：{{model.post_link}}</span> </p>
          <p><a href="javascript:window.print()"><a-icon type="printer" />[打印]</a></p>
        </div>
        <section id="main">
          <div id="zoom" :class="'fsize'" :style="{fontSize : fsize}" v-html="model.post_content">
          </div>
          <div class="downfile" v-html="downfile(model)">
          </div>
        </section>
      </div>
    </div>
  </section>
  </Collection>
</article>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'Expert',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      fsize: '16px'
    }
  },
  methods: {
    setFontSize (fontSize) {
      this.fsize = fontSize
    },
    downfile (post) {
      if (post.attachment) {
        const postURL = '/gs12348/api/post/' + post.post_id + '?_file=attachment&_mode=inline'
        console.log(postURL)
        return '<a href="' + postURL + '">下载附件</a>'
      } else {
        return ''
      }
    }
  },
  activated () {
    this.id = this.$route.params.id
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }

}
</script>
<style scoped src="../../assets/css/article.css"></style>
